/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

@import "reset";

@import "mixins";

@import "partials/html";
@import "partials/helpers";

@import "partials/containers";
@import "partials/sidebar";
@import "partials/comments";

@import "partials/headers";
@import "partials/buttons";
@import "partials/lightbox";
@import "partials/plugins";


div.container { background:url('../img/layout/container-bg.gif') center repeat; }


section.header header, section.body, section.footer footer { width:940px; margin:0 auto; }
section.body { margin-top:20px; margin-bottom:15px; }


/* header */
section.header { 
	background:url('../img/layout/header-bg.png') top left repeat-x; 
	margin-bottom:20px;
	border-bottom:1px solid #4d4d51;
	padding-bottom:12px;
	@include box-shadow(0,3px,6px,0,#333);
	.logo {
		
		float:left;

		margin:0;
		a { background:url('../img/image-replace/logo.png'); display:inline-block; height:41px; margin:8px 0 0; width:202px; }
	}
}


/* footer */
section.footer { 
	background:url('../img/layout/footer-bg.png') top center repeat; 
	height:100px;
	p { text-align:right; padding-top:40px; }
}


nav.main {
	float:right;
	margin-top:25px;
	ul { display:inline-block; }
	li { display:inline; list-style:none; font-size:1em; }
}

li.nav-sep { padding:0 5px; }
img.header-rss { margin-bottom:-2px; }


/* content */
.content { float:left; width:63%; }
div.entry-content ol, div.entry-content ul { padding:0 0 0 20px; }
div.entry-content li { padding-bottom:8px; }
div.content iframe, div.content ins { display:inline; float:left!important; padding: 0 10px 10px 0!important; }
div.entry-tags { float:right; }
div.content div.entry-meta { margin-bottom:18px; }
div.content div.entry-meta.bottom-tags { margin:30px 0 30px; }
div.content div.entry-meta.top-tags { margin:10px 0 0; }
.fullwidth-content div.content { width:100%; }
div.content div.page h1 { margin-bottom:15px; }
div.content.entrylist h3 { margin-bottom:0; border:0; font-size:2em; }
div.content.entrylist div.entry-meta { margin-bottom:8px; }
div.entry.noPosts { margin-top:100px; text-align:center; }
div.entry.noPosts h2 { text-align:center; margin-bottom:80px; }
div.entry.noPosts h2 { font-size:2.6em; line-height:1em; }
div.entry.noPosts ul.noPostsList li { display:inline; padding:0 5px 0 0; margin:0 10px 0 0; border-right:1px solid #4C4C52; }
div.entry.noPosts ul.noPostsList li:last-child { border:0; }
p.share { padding:0; margin:0 auto; width:420px; }
p.share a { height:32px; width:32px; display:block; float:left; margin:0 5px 0 5px; }
p.share a img { opacity:0.3; filter:alpha(opacity=30); height:32px; width:32px; }
p.share a:hover img { opacity:1; filter:alpha(opacity=100); }
div#nav-above { margin-bottom:15px; }
div#nav-below { margin-top:35px; }
div.navigation div.nav-next { float:right; text-align:right; padding:3px; }
div.navigation div.nav-previous { float:left; padding:3px; }
div.navigation div.nav-previous a, div.navigation div.nav-next a { font-size:1.2em; }
div.navigation div.nav-archives { font-size: 12px; text-align:center; width:100%; }
div.navigation div { font-size:0.9em; width:46%; }


/* home specific */
div.home-left { float:left; width:47%; }
div.home-right { float:right; width:49%; }
div.content.home h3 { font-size:1.9em; line-height:1em; }
div.content.home div.vspw-item h3 { display:inline; line-height:0.9em; }
div.content.home div.entry-meta { margin-bottom:4px; }
div.content.home div.vspw-item div.entry-meta { padding-left:10px; display:inline; } 
div.content.home p { padding:0; }
div.home-post { margin-bottom:35px; }
div.home-post .entry-content { font-size:13px; line-height:20px; }
div.home-post.vspw-item { margin-bottom:12px; }
input.search-box {  width:373px; background-color:#767676; }


section.intro-wrapper { 
	background-color:#4d4d51; 
	border-bottom:1px solid #333337; 
	border-top:1px solid #6b6a71; 
	-moz-box-shadow:0px 0px 8px #333333; 
	-webkit-box-shadow:0px 0px 8px #333333; 
	box-shadow:0px 0px 8px #333333; 
	
	.intro { border-bottom:1px solid #6b6a71; border-top:1px solid #333337; }
	.intro-inner { margin:0 auto 0; width:940px; }
	.intro-pic { display:block; position:absolute; width:102px; height:102px; margin:3px 0 0 20px; background-repeat:no-repeat; }
	.intro-text { font-family:helvetica; float:right; font-size:1.1em; padding:10px 20px 15px 0; width:770px; }
	.intro-text a { font-weight:bold; }
	.intro-text h1 { font-size:1.75em; margin-bottom:7px; }
	.intro-text p { padding:0; }
}



/* custom */
div.archive-content p { padding:0; }
div#projects-page div.entry-content div { display:inline; float:left; margin:0 8px 0 0; text-align:center; width:188px; }
div#projects-page div.entry-content div img { width:180px; margin:0; }
div#projects-page div.entry-content div p { text-align:center; }
span.facepalm { font-size:0.65em; padding-left:20px; }
span.highlight { background-color:#57565c; padding:1px; }
div.mouFlickrGallery a.flickrlink { display:block; float:left; height:75px; width:75px; border:2px solid #333; margin:0 5px 5px 0; }
div.mouFlickrGallery a:hover.flickrlink { border:2px solid #fb41ab; }
div.mouFlickrGallery img.flickrimg { margin:0; }
p.xrgw_widget a, p.mowp_tiles a, p.flickr_photos a, p.instagram-list a, p.xbox-recently_played a { width:64px; height:64px; display:block; float:left; margin:0 3px 0 0; border:3px solid #888; -moz-border-radius:3px; -webkit-border-radius:3px; border-radius:3px; }
p.xrgw_widget a img, p.mowp_tiles a img, p.flickr_photos a img, p.instagram-list a img, p.xbox-recently_played a img { width:64px; height:64px; }
p.xbox-recently_played a { width:auto; height:106px; margin:0 6px 0 0; } 
p.xbox-recently_played a img { width:auto; height:106px; }
p.xrgw_widget a:hover, p.mowp_tiles a:hover, p.flickr_photos a:hover, p.instagram-list a:hover, p.xbox-recently_played a:hover  { border:3px solid #fb41ab; }
div.sidebar p.xrgw_widget a, div.sidebar p.mowp_tiles a, div.sidebar p.flickr_photos a { margin-right:5px; }
div.sidebar p.flickr_photos a { width:64px; height:64px; }
table.lifestream { margin-top:-60px; }
table.lifestream h2 { margin:40px 0 10px; }
td.lifestream_icon a img { background-color:#fff; }
div#contact-success { border: 2px solid #a0e19d; background-color: #ccffd6; margin-bottom: 30px; padding:8px; color:#222; }
div#contact-err { border: 2px solid #ff8181; background-color: #ffcccc; margin-bottom: 30px; padding:8px; color:#222; }
div#contact-err ul { padding:0; }
div#contact-success h2, div#contact-err h2 { color:#222; }


.back-to-top { float:left; display:none; background-color:#333; border:1px solid #222; border-bottom:0; color:#bbb; position:fixed; bottom:0; right:30px; padding:10px 10px 5px; z-index:99999; -moz-border-radius:10px 10px 0 0; -webkit-border-radius:10px 10px 0 0; border-radius:10px 10px 0 0; }
.back-to-top:hover { background-color:#3a3a3a; text-decoration:none; }
