h1, h2, h3, h4, h5, h6 { font-family:'Trebuchet MS',Verdana,Sans-Serif; }
h1 a:hover, h2 a:hover { text-decoration:underline; }
h1 { text-align:left; font-size:2.4em; font-weight:normal; letter-spacing:-1px; color:#eee; line-height:24px; margin-bottom:15px; }
h2 { text-align:left; font-size:2.0em; font-weight:normal; letter-spacing:-1px; color:#aaa; }
h3 { text-align:left; font-size:1.8em; font-weight:normal; letter-spacing:-1px; color:#eee; margin: 35px 0 20px; border-bottom:1px dashed #666; padding-bottom:5px; line-height:1em; }
h4 { text-align:left; font-size:1.6em; font-weight:normal; letter-spacing:-1px; color:#eee; margin:0 0 10px; }
div.content h1 { margin-bottom:3px; }
div.content.home h2 { margin:0 0 10px; border:0; }
div.content.home h3 { margin:0; border:0; padding:0 0 3px; }
div.comments h4 { font-size:2.8em; font-weight:normal; letter-spacing:-1px; line-height:28px; margin:50px 0 30px; text-align:center !important; }
div.sidebar h4 { font-size:2.3em; margin-bottom:4px; padding-bottom:4px; border-bottom:1px dashed #666;  }
div.entry-content h2 { color:#eee; margin:20px 0 10px; }

h2.latest-blog-posts, h2.momentile, h2.moblog, h2.xbox, h2.twitter, h2.flickr, h2.search, h2.home-header { text-shadow: 0px 0px 2px #333; filter: dropshadow(color=#333333, offx=0, offy=0); font-family:Helvetica,Arial,sans-serif; font-weight:normal; font-size:26px; line-height:30px; color:#eee; text-shadow: 0px 0px 3px #333; filter: dropshadow(color=#333, offx=0, offy=0); }