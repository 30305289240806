/* buttons */
a.more, a.more span { display:inline-block; font-size:13px; }
a.more { border:1px solid #2b292c; }
a.more span { padding:5px; border-top:1px solid #4d4b4f; border-left:1px solid #4d4b4f; border-bottom:1px solid #39373b; border-right:1px solid #39373b; color:#eee; }
a.more span:hover { border-bottom:1px solid #4d4b4f; border-right:1px solid #4d4b4f; border-top:1px solid #39373b; border-left:1px solid #39373b; }
a:hover.more, li.aktt_more_updates a:hover { background-position:0 0; }
a.contactme { display:block; width:86px; height:16px; background:url('../img/buttons/contactme.gif') 0 -16px no-repeat; margin:3px 0 0 -2px; float:left; }
a:hover.contactme { background-position:0 0; }
a.comment-reply-link { display:block; width:52px; height:16px; background:url('../img/buttons/reply.gif') 0 -16px no-repeat; }
a:hover.comment-reply-link { background-position:0 0; }
a.read-more { display:block; width:90px; height:16px; background:url('../img/buttons/readarticle.gif') 0 -16px no-repeat; margin-top:7px; }
a:hover.read-more { background-position:0 0; }
/*a.more span { display:none; }*/
p.xrgw_linkback_text, p.flickr_linkback_text, p.mowp_linkback_text { margin-top:10px; }
input.search-but { background:url('../img/buttons/search.gif') 0 -16px no-repeat; width:52px; height:16px; border:0; cursor:pointer; } 
input:hover.search-but { background-position:0 0; }


/* download block */
div.download { width:435px; margin:10px auto; text-align:center; }
div.download a { width:125px; height:40px; margin:0 10px; text-indent:-3000px; display:block; float:left; }
a.download-zip { background:url('http://static.mou.me.uk/../img/download.png') 0 0 no-repeat; }
a.download-rar { background:url('http://static.mou.me.uk/../img/download.png') 0 -40px no-repeat; }
a.download-targz { background:url('http://static.mou.me.uk/../img/download.png') 0 -80px no-repeat; }