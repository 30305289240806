.clearfix:after, section.header:after { content: '.'; display: block; clear: both; height: 0; visibility: hidden; }

span.clear { display:block; clear:both; }
span.image-replace, div.image-replace { display:none; }
a img.bdr { border:4px solid #2B292C; margin-right:8px; }
a:hover img.bdr { border:4px solid #fb41ab; }
div.header a, div.footer a { color:#fff; }
div.header p, div.footer p { color:#535353; }
.alignright, img.alignright { float:right; margin:2px 0 10px 4px!important; }
.alignleft, img.alignleft { float:left; margin:2px 10px 4px 0!important; }
.aligncenter,img.aligncenter { display:block; margin:1em auto; text-align:center; }
img[align=left], img.alignleft { margin-right:10px; border:1px solid #555; padding:2px; }
img[align=right], img.alignright { margin-left:10px; }
img.avatar { float:left; border:2px solid #2f2e31!important; margin:0 4px 0 0; }