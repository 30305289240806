.sidebar { 
	float:right; 
	width:32%; 
	font-size:0.8em;
	
	p { margin-bottom:0px; padding-bottom:2px; }
	ul ul { padding:0; }
	ul li { margin-bottom:40px; list-style-type:none; }
	ul li li, div.sidebar ul li li li, div.sidebar ul li li li li { margin:0; }
	ul li li { padding:2px 0; }
	li#searchbox { margin-bottom:20px; }
	input.side-search-box { width:215px; background-color:#767676; }
	li.author { background:#3b3b3d; border:2px dashed #38373a; padding:7px; font-size:1.4em; -moz-border-radius:5px; -webkit-border-radius:5px; border-radius:5px; }
	li.author img.avatar { height:64px; width:64px; margin-right:10px; }
}

div.fullwidth-content .sidebar { display:none; }