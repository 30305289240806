div.comments {
	
	ol#commentlist { 

		padding:0; 
		
		li { font-size:1em; margin-bottom:25px; border-bottom:1px solid #444346; }
		li:last-child { border-bottom:0; }
		li li { margin:0 0 0 30px; }
		cite.comment-author { font-style:normal; font-weight:bold; font-size:1.7em; line-height:1.2em; padding-top:4px; }
		li.byuser div.comment { background:#4b4a4f; border:1px solid #2f2e31!important; }
		div.comment { 
			padding:10px; 
			margin-bottom:25px; 
		
			.comment-permalink { color:#eee; }
			.comment-text { margin:5px 0 0; }
		}
	}
}

ul.reply-form li { margin-bottom:7px; }