body { font-size:62.5%; font-family:'Lucida Grande',Verdana,Arial,Sans-Serif; background-color:#29292a; color:#eee; padding-bottom:0.1em;  }
body, p, li { line-height:1.7em; }
p, li { font-size:1.15em; }
hr {  border-top:1px solid #38383c; border-bottom:1px solid #4c4c52; border-right:0; border-left:0; width:70%; height:2px; margin:25px auto; }
p { padding-bottom:15px; }
a { color:#fb41ab; text-decoration:none; }
a:hover { text-decoration:underline; }
blockquote { background:#4b4a4f url(../img/icons/quote.png) no-repeat scroll 15px 50%; border:2px dashed #2f2e31; font-style:italic; margin:20px 0; padding:20px 20px 20px 55px; -moz-border-radius:8px; -webkit-border-radius:8px; border-radius:8px;  }
blockquote p:last-child, pre p:last-child { padding:0; }
pre { margin:20px 0; padding:15px 15px; background-color:#ddd; color:#111; border:2px dashed #2f2e31; overflow:auto; font-family:"Courier New",Courier,monospace; line-height:16pt; font-size:1.1em; }
code { font-family:"Courier New",Courier,monospace; font-size:1.1em; background-color:#57565c; }
textarea { width:95%; }
input[type=text], input[type=email], input[type=url], input[type=password] { width:300px; }
input[type=text], input[type=email], input[type=url], input[type=password], textarea { border:1px solid #2b292c; padding:10px; font-size:1.4em; -moz-border-radius:5px; -webkit-border-radius:5px; border-radius:5px;  }